import { isInViewport } from '../helpers/is-in-viewport';
const counterClass = 'js-number-counter'
const startedClass = 'is-started'

const counters = document.querySelectorAll('.' + counterClass);
if (counters.length > 0) {
    document.addEventListener('scroll', function () {
        const counterNumbers = counterElem => {
            if (isInViewport(counterElem) && !counterElem.classList.contains(startedClass)) {
                const finalValue = parseInt(counterElem.dataset.count);
                const animTime = parseInt(2500);
                const initTime = performance.now();
                // Interval
                let interval = setInterval(function() {
                    let t = (performance.now() - initTime) / animTime;
                    let currentValue = Math.floor(t * finalValue);
                    if (currentValue <= finalValue) {
                        counterElem.innerText = currentValue;
                    }
                    else {
                        counterElem.innerText = finalValue;
                    }
                    if (t >= 1 || currentValue == finalValue) {
                        clearInterval(interval);
                    }
                }, 50);
                counterElem.classList.add(startedClass)
            }
        }
        counters.forEach(counter => counterNumbers(counter));
    })
}